/**
 * List Schema of Contract Nep 17
 * @author Lopes
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

export class ListContractNep17Schema extends DefaultSchema {
  readonly name = 'ListContractNep17'

  readonly fieldSet: FieldSet<TokensTableItem> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    idContractPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    symbol: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
