





































































































import {Component, Vue} from 'vue-property-decorator'
import {ListContractNep17Schema} from '@/schema/resource/ContractNep17/ListContractNep17Schema'
import ContractNep17Modal from '@/components/ContractNep17Modal.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({
  components: {
    ContractNep17Modal,
  },
})
export default class ListContractNep17View extends Vue {
  schema = new ListContractNep17Schema()
  collection = new TokensTableItemsCollection()
  filterOpen = false

  async mounted() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.orderBy = 'order'
    this.collection.asc = true
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.expand()]

    await this.$await.run('hardQuery', () => Promise.all(promises))
  }

  async changeOrder(item: TokensTableItem, increment: number) {
    if (!item.marketInformation) return

    if (item.marketInformation.order === null) item.marketInformation.order = 0
    item.marketInformation.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.populate()
  }

  async populate() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
