



































import {Component, Prop, Vue} from 'vue-property-decorator'
import {InputContractNep17Schema} from '@/schema/resource/ContractNep17/InputContractNep17Schema'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({})
export default class ContractNep17Modal extends Vue {
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem

  showModal: boolean = false
  schema = new InputContractNep17Schema()
  open = false

  async persistContract() {
    await this.token.persistContract()
    this.$toast.success('system.success.persist')
    this.showModal = false
    this.$emit('persisted')
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}
